import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";  
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
 
const Footer = () => {
 
    const [scroll, setScroll] = useState(0);
    const [top, setTop] = useState(0);
  
    useEffect(() => {
      setTop(100);
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      animateScroll.scrollToTop();
    };
  
    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    return(
    <footer className="footer-section">
        <div className="container">
            <div className="row"> 
                {/*  Start:About  */}
                <div className="col-lg-3 col-sm-12">
					<Widget title="About us">
                        <p>In-home and office cleaning services.<br />Servicing Houston area now.</p>
                    </Widget>
                </div> 
                {/*  End:About  */}
	    <div className="col-lg-3 col-sm-12">
	    <Widget title="Contact Us">
     	    Easy Maid LLC <br />
	    25402 Katy Mills Pkwy, Katy, TX 77494<br />
	    <a style={{ color: 'white' }} href="mailto:sales@easymaid.cleaning">sales@easymaid.cleaning</a><br /><br />
	    <a style={{ color: 'white' }} href="tel:+1-281-886-8867">tel:+1-281-886-8867</a><br />
	    <a style={{ color: 'white' }} href="fax:+1-281-407-7510">fax:+1-281-407-7510</a>
	    </Widget>
	    </div>

                {/*  Start:Quick Link  */} 
                {/*  End:Quick Link  */}
 
                {/*  Start:Latest post   */}
                <div className="col-lg-3 col-sm-12"> 
                </div> 
                {/*  End:Latest post  */}


                {/*  Start:Newsletter  */} 
                <div className="col-lg-3 col-sm-12"> 
                </div> 
                {/*  End:Start Subscribe  */} 
 

                {/*  Start:Subfooter  */}
                <div className="subfooter"> 
                    <div className="copyright_text"> &copy; {new Date().getFullYear()} <Link to={process.env.PUBLIC_URL + "/"}>Easy Maid LLC</Link>
                    </div> 
                    <button className={`scrollup ${scroll > top ? "show" : ""}`} onClick={() => scrollToTop()} > 
                        <span className="icon-glyph-203"></span>
                    </button> 
                </div> 
                {/* End:Subfooter  */}

            </div>
        </div> 

    </footer> 

        )
 
}

 
export default Footer;
