import React from 'react';
import PromoList from './PromoList';
import PromosData from '../../data/WelcomePromo/promo';
import SectionTitle from "../SectionTitles/SectionTitle";


function PromoContainer() {
    return (
        <section className="service_list_section">
            <div className="container">
                {/* Start: Heading */}
                <SectionTitle 
                    title="Easy Maid Cleaning Services" 
                    subtitle="Servicing the greater Houston area with in-home or office cleaning.  Get in touch today for a quote!"
                />

                <div className="row service_list_warp">
                </div>
            </div>
        </section>
    );
}

export default PromoContainer;
